<template>
    <Transition>
        <div v-if="drawer.state.active">
            <div class="drawer">
                <Pdf :file="drawer.state.data" height="800px" width="100%" />
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    components: {
        Pdf: () => import(/* webpackChunkName: "Pdf" */ "@/components/pdf.vue"),
    },

    data() {
        return {
            drawer: this.$drawer,
        };
    },
};
</script>

<style scoped>
.drawer {
    z-index: 1;
    background-color: #f0eff4;
    box-shadow: -10px 0px 10px 1px #aaaaaa;
    padding: 10px;
    position: fixed;
    width: 30%;
    right: 0px;
    bottom: 0px;
    top: 0px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
